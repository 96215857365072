<template>
  <section class="section section-standalone" id="pikobar-wrapper">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="title-font text-size-title text-uppercase mb-0">
            PIKOBAR
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 doc" v-if="showContent">
          <iframe
            :src="src"
            scrolling="yes"
            frameborder="0"
            id="pikobar"
            width="100%"
            height="450px"
          ></iframe>
        </div>
      </div>
      <div
        class="row sumber-data"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br>
                                Kontak : ${
                                  sumber_data.kontak == null
                                    ? '-'
                                    : sumber_data.kontak
                                }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'PIKOBAR'
    };
  },
  data() {
    return {
      showContent: false,
      base: window.links.pikobar_init,
      src: `${this.base}`,
      sumberTooltip: false,
      sumber_data: {
        sumber: '-',
        updated_at: null
      }
    };
  },
  mounted() {
    var body = document.querySelector('body');
    body.style.overflowY = 'hidden';

    this.getData();
    this.updateIframe();
  },
  methods: {
    async updateIframe() {
      await this.sleep(11000);
      document.getElementById('pikobar').src = window.links.pikobar;
    },
    getData() {
      this.src = `${this.base}`;

      window.axios.post(window.base_api + 'page/pikobar').then(response => {
        if (response.status == 200)
          this.sumber_data = response.data.sumber_data;
      });
      setTimeout(() => {
        this.showContent = true;
      }, 1000);
    },
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
};
</script>

<style scoped>
.doc {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  iframe {
    height: 1000px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  iframe {
    height: 475px !important;
  }
}
</style>
